import cn from 'classnames'
import React from 'react'

interface SkeletonLoadingProps {
  className?: string
  isShow?: boolean
}

/* const SkeletonLoading: React.FC<SkeletonLoadingProps> = ({ className }) => {
  return (
    <div className="shadow w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6">
          <div className={cn('h-4', className)}></div>
        </div>
      </div>
    </div>
  )
} */
const SkeletonLoading: React.FC<SkeletonLoadingProps> = ({ className = '', isShow = false }) => {
  if (!isShow) return <></>

  return (
    <div className="w-full">
      <div className={cn(`h-1.5 w-full ${className}-100 overflow-hidden`)}>
        <div className={cn('progress w-full h-full left-right', className)}></div>
      </div>
    </div>
  )
}
export default SkeletonLoading
