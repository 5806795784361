export { default } from 'src/containers/Home'
import { GetStaticProps } from 'next'
import { THEME } from 'src/config/config'
import { HomePageProps } from 'src/containers/Home/Home'
import { apolloSdk } from 'src/graphql/apolloSdk'
import { IPopularTopic, ITopicItemFragment } from 'src/graphql/generated/hooks'

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  let props: HomePageProps
  try {
    if (THEME === 'legaltech') {
      const [
        { topics },
        { testimonialsPlural },
        // { premiumAudiences },
        { popularTopics },
        //{ topics: premiumTopics },
        //{ subTopics: premiumSubTopics },
      ] = await Promise.all([
        apolloSdk.getTopicsForHeader(), //apolloSdk.getTopicsWithoutSubs(),
        apolloSdk.globalTestimonials(), //1
        /*  apolloSdk.premiumAudiences({
        filters: { shouldShowOnHomepage: { eq: true } },
        sort: ['name:desc'],
      }), */
        apolloSdk.popularTopics({ vendorLimit: 6 }),
        //apolloSdk.premiumTopics(),
        //apolloSdk.premiumSubTopics(),
      ])

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const premiumCategories: any[] = (premiumTopics?.data || []).concat((premiumSubTopics?.data || []) as any)
      props = {
        theme: 'legaltech',
        //audiences: premiumAudiences?.data || [],
        topics: (topics?.data || []) as ITopicItemFragment[],
        testimonials: testimonialsPlural?.data || [],
        popularTopics: popularTopics as IPopularTopic[],
        //premiumCategories,
      }
    } else {
      const [{ topics }, { iltaSponsorships }] = await Promise.all([
        apolloSdk.getTopicsWithoutSubs(),
        apolloSdk.getIltaSponsorships(),
      ])
      props = {
        theme: 'ilta',
        topics: (topics?.data || []) as ITopicItemFragment[],
        iltaSponsorships: iltaSponsorships?.data || [],
      }
    }

    return {
      props,
      revalidate: 60,
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error occurred in getStaticProps of Home:', error)
    // Graceful fallback in case of an upstream error
    return {
      notFound: true,
      revalidate: 60, // Reattempt fetching during the next static regeneration
    }
  }
}
